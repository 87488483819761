<template>
  <div class="gpt-main">
    <div class="result">
      <div v-for="(item, idx) in vo" :key="idx">
        <div class="user" v-html="item.html" v-if="item.role == 'user'"></div>
        <div class="assistant" v-html="item.html" v-else></div>
      </div>
      <div class="wait" v-if="waiting">
        <el-skeleton />
      </div>
    </div>
    <div class="input">
      <div>
        <el-input type="textarea" v-model="chat" :rows="5"></el-input>
      </div>
      <div class="btn">
        <el-button type="primary" @click="onSend" :disabled="waiting">发送</el-button>
        <el-button @click="newThread">新的对话</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";
import { openAIApi } from "@/api/openAIApi.js";
//import { nanoid } from "nanoid";
import { customAlphabet } from "nanoid";
import { mdUtil } from "@/util/mdUtil";
const markdownIt = require("markdown-it")({});

export default {
  components: {},
  data() {
    return {
      waiting: false,
      vo: [],
      chat: "",
      req: {
        model: "gpt-3.5-turbo",
        messages: [], // role, content
      },
      id: "",
    };
  },
  methods: {
    newThread() {
      const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 8);
      let id = nanoid(); //=> "4f90d13a42"

      this.vo = [];
      this.req.messages = [];
      this.id = id;
      this.waiting = false;
    },
    onSend() {
      this.req.messages.push({
        role: "user",
        content: this.chat,
      });
      this.vo.push({
        role: "user",
        content: this.chat,
        html: markdownIt.render(this.chat),
      });
      //console.log(this.chat, markdownIt.render(this.chat));
      this.waiting = true;
      openAIApi
        .chatCompletion(this.id, this.req)
        .then((ret) => {
          console.log(ret);
          let { code, data } = ret;
          if (code == 0) {
            this.vo.push({
              role: data.choices[0].message.role,
              content: data.choices[0].message.content,
              html: markdownIt.render(data.choices[0].message.content),
            });
            this.req.messages.push(data.choices[0].message);
          } else {
            msgLogger.error(ret.msg);
          }
          this.waiting = false;
        })
        .catch((err) => {
          this.waiting = false;
        });

      this.chat = "";
    },
  },
  mounted() {
    this.newThread();
  },
};
</script>

<style lang="less" scoped>
.gpt-main {
  width: 90%;
  display: flex;
  flex-direction: column;
  height: 100%;
  .result {
    flex: 1;
    text-align: left;
    //border: 1px solid #e4e7ed;
    .user {
      background: #e4e7ed;
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
    }
    .assistant {
      background: rgb(123, 127, 211);
      color: white;
      border-radius: 10px;
      padding: 10px;
      margin: 10px;
    }
    .wait {
      margin: 10px;
    }
  }
  .input {
    margin: 20px;
    .btn {
      margin: 10px;
    }
  }
}
</style>
