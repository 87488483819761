import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const chatCompletion = (id, req) => {
  return ajax.post(`${base}/api/itedu/v1/openai/chat-completion?id=${id}`, req);
};

export const openAIApi = {
  chatCompletion: chatCompletion,
};
